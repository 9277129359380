import React from 'react'
import { useEffect, useState } from "react";
import { s3bucket, apiBaseURL } from '../../Common/Constant';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar'; 
import Pagination from '../Layout/Pagination';

import { putAPI,postAPI, get } from '../../ApiService';

function Module() {
    useEffect(() => {
        document.title = "Module - GoToSample"
    },);
    const [modules, setModule] = useState([]);
    const [actions, setActions] = useState([]);
    const [pageInfo, setPagination] = useState({});
    const [fileList, setFileList] = useState(null);
    const token = localStorage.getItem("token");
    const headers = {
        // 'Accept': 'application/json',
         'Content-Type': 'application/json',
        // 'Origin': '',
        // 'Host': 'api.producthunt.com'
        'Authorization': 'Bearer '+token+''
    };
    const changePage =(p)=>{
        pageInfo.current_page = p;
        getList();
    }
    const getList = async(page=1) => {
        let data = {page: pageInfo.current_page,recPerPage: 50, q: "",func:''}
        let res = await get('/urm/module/list', data);        
            if(res.status === 1){
               // let data = res;//.json();
                let respArr = [];
                    respArr = res.payload;
                    respArr.forEach((r, index) => { r.serial = index + 1; });
                
                 setModule(respArr);
                 setPagination(res.paginationInfo);
            }
      }

      const getActionList = async(page=1) => {
        let data = {page: pageInfo.current_page,recPerPage: 50, q: "",func:''}
        let res = await get('/urm/action/list', data);        
            if(res.status === 1){
               // let data = res;//.json();
                let respArr = [];
                    respArr = res.payload;
                    setActions(respArr);
            }
      }


const submitModule = async (e) => {
    // e.preventDefault();
    //setErrors();
    let parentId = document.getElementById('parentModule').value;
    let name = document.getElementById('moduleName').value;
    //let imageURL = document.getElementById('moduleFileUrl').value;
    let formData = {
        name:name,
        parentId
       // imageURL:imageURL
    };
    // const formErrors = validateFormData(formData)
    // if(Object.keys(formErrors).length > 0){
    //     //setErrors(formErrors)
    // }else{
        let res = {};
        if(document.getElementById('moduleId').value == ""){
            res = await postAPI('/urm/module/add/', formData);
        }else{
            let moduleId = document.getElementById('moduleId').value; 
            res = await putAPI('/urm/module/update/'+moduleId, formData);
        }
        if(res.status === 1){
            setModuleFields();
            getList();           
         }
        //else{
        //     //setErrors({...errors,['submit']:res.message})
        // }
    //}
}


const updateModuleStatus = async (rolId,status) => {
    // e.preventDefault();
    //setErrors();
    status = status==0?1:0;
    let formData = {
        id:rolId,
        status:status
    };
    // const formErrors = validateFormData(formData)
    // if(Object.keys(formErrors).length > 0){
    //     //setErrors(formErrors)
    // }else{
        let res = await putAPI('/urm/module/update-status/'+rolId+'/'+status, formData);
        if(res.status === 1){
            getList();           
         }
        //else{
        //     //setErrors({...errors,['submit']:res.message})
        // }
    //}
}


const setModuleFields = (id,name,parentId)=>{
    document.getElementById('moduleId').value = id?id:'';
    document.getElementById('moduleName').value = name?name:'';
    document.getElementById('parentModule').value = parentId?parentId:'';
    
    // document.getElementById('moduleFileUrl').value = url?s3bucket+url:'';
    if(!id){
        document.getElementById('cancelButton').style.display = 'none';
        //document.getElementById('moduleFile').value = '';
        
    }else{
        document.getElementById('cancelButton').style.display = 'inline-block';
    }
    
}


useEffect(() => {
getList()
// getActionList()
}, [])

    return (
        <div>
            <Header />
            {/* <Sidebar /> */}
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* <!-- start page title --> */}
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xl-12 col-md-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <div>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                {/* <li className="breadcrumb-item"><a >Dashboards</a></li> */}
                                                 <li className="breadcrumb-item active">Add Module</li> 
                                            </ol>
                                        </div>
                                        <h4 className="mb-sm-0">Add Module</h4>
                                        </div>
                                        

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className='row'>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <input type="hidden" className="form-control" id="moduleId"  />

                                        <label className="form-label mb-0">Parent Module </label>
                                        <select className="form-control" id="parentModule">
                                            <option value="">Select parent module</option>
                                        {modules.map((module,index) => (
                                            module.parentId == null && <option value={module.id}>{module.name}</option>                                            
                                        ))}
                                        </select>
                                        {/* <input type="text" className="form-control" placeholder="Enter module name" id="moduleName" /> */}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Module </label>
                                        <input type="text" className="form-control" placeholder="Enter module name" id="moduleName" />
                                    </div>
                                </div>
                                {/* <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Actions </label>
                                        
                                        {actions.map((action,index) => (
                                            <>
                                            <input type="checkbox"  />   {action.name}                                       
                                        </>
                                        ))}
                                        
                                        
                                    </div>
                                </div> */}
                                
                                <div class="col-md-4">
                                    <img class="mt-3" id="imageView" style={{display:'none',height:"50px", border:"2px solid grey"}} src ="" />
                                    <div class="mt-3" style={{display:'inline-block'}}>
                                        <label> &nbsp; </label>
                                        <button type="button" class="btn btn-primary mx-2" onClick={()=>submitModule()}>Submit</button>
                                    </div>
                                    <div class="mt-3" style={{display:'inline-block'}}>
                                        <label> &nbsp; </label>
                                        <button type="button" className="btn btn-danger mx-2" id="cancelButton" style={{display:'none'}} onClick={()=>setModuleFields()}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-xl-12 col-lg-12">
                                    <div class="card">

                                        {/* <!-- end card header --> */}

                                        <div class="card-body" style={{padding: "10px"}}>
                                            <table class="table  table_client">
                                                <thead>
                                                    <tr class="bg-primary-1" style={{ color: "white", width:"200px" }}>
                                                        {/* <th scope="col">#</th> */}
                                                        {/* <th scope="col">Image</th> */}
                                                        <th scope="col" style={{width:"300px" }}>Module Name</th>
                                                        {/* <th scope="col">User Count</th> */}
                                                        <th scope="col">Status</th>
                                                        <th scope="col" style={{textAlign:"right"}}>Action</th>
                                                    </tr>
                                                </thead>
                                                
                                                {modules.length > 0 && (
                                                <tbody>
                                                    {modules.map((module,index) => (
                                                        <>
                                                        <tr key={module.id} style={{background:"#f5f5f5"}}>                                                        
                                                        <td> {module.name}</td>
                                                        <td>
                                                            <div class="form-check form-switch" >
                                                                <input class="form-check-input" type="checkbox" module="switch" id="flexSwitchCheckDefault" checked={module.status==1?"checked":""} onClick={()=>updateModuleStatus(module.id,module.status)} />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <button class="btn btn-sm pull-right btn-info" onClick={()=>setModuleFields(module.id,module.name)}>Edit </button>
                                                            
                                                        </td>
                                                    </tr>

                                                    {
                                                        module.children.length > 0 && 
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <table class="table  table_client">
                                                                {module.children.map((child,index) => (
                                                                    <tr key={child.id}  >
                                                                    <td style={{borderLeft:"2px solid #34b4eb",width:"300px", paddingLeft:"20px" }}>{child.name}</td>
                                                                    <td>
                                                                        <div class="form-check form-switch" >
                                                                            <input class="form-check-input" type="checkbox" module="switch" id="flexSwitchCheckDefault" checked={child.status==1?"checked":""} onClick={()=>updateModuleStatus(child.ID,child.status)} />
                                                                        </div>
                                                                    </td>
                                                                    <td style={{borderRight:"2px solid #34b4eb"}}>
                                                                       <div>
                                                                        <button class="btn btn-sm pull-right btn-warning" onClick={()=>setModuleFields(child.ID,child.name, module.id)}>Edit </button>
                                                                        </div> 
                                                                        
                                                                    </td>
                                                                </tr>
                                                                ))}
                                                                </table>
                                                            </td>                                                        
                                                        </tr>
                                                        }

                                                    </>
                                                    ))}
                                                </tbody>
                                                )}
                                            </table>
                                            <Pagination pageInfo={pageInfo} changePage={changePage} />                                            
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <!-- end row--> */}



                            {/* <!-- end row --> */}

                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default Module;